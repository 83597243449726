import React from "react";
import TextPageLayout from "../components/layouts/TextPageLayout";
import Icon from "../components/Icon";
import { GhostIcon } from "../icons";
import { Link } from "gatsby";
import SupportPrompt from "../components/SupportPrompt";

function ErrorPage() {
  console.error("Entered 404 page");

  return (
    <TextPageLayout title="Looks like you've found a broken page">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon src={GhostIcon} title="Ghost icon." containerWidth={200} />
      </div>
      <p>
        Don't worry, you're in good company. This is where the ghosts like to
        hang out since no one's exorcising here.
      </p>
      <p>
        <SupportPrompt>We'd love to know how you ended up here.</SupportPrompt>
      </p>
      <p>If you're stuck for things to do, you can:</p>
      <ul style={{ listStyle: "circle", marginLeft: "16px" }}>
        <li>
          <Link to="/home/">Head back home</Link>
        </li>
        <li>
          <Link to="/skill-tree">Update your Skill Tree</Link>
        </li>
        <li>
          <Link to="/exercises">Learn some awesome exercises</Link>
        </li>
        <li>
          <Link to="/workouts">Build your own workout</Link>
        </li>
      </ul>
    </TextPageLayout>
  );
}

export default ErrorPage;
